<template>
    <div
        v-show="discountModalVisible && hasActiveDiscount"
        class="modal discount-modal"
    >
        <div
            class="overlay"
            @click="handleCloseModal"
        />
        <div class="wrapper">
            <div class="container">
                <div class="modal-header">
                    <h2 class="modal-title">{{ $t('Thank you for your purchase!') }}</h2>
                    <img
                        src="@/assets/img/close.svg"
                        @click="handleCloseModal"
                        class="close"
                    />
                </div>
                <p class="discount-message">
                    As a complimentary gift, you have <span class="highlight">{{ discountDuration }}min</span> of
                    <span class="highlight">{{ discountPercentage }}%</span> discount to catch more skins into your
                    account
                </p>
                <div
                    class="product-list-section"
                    v-if="products && products.length"
                >
                    <div class="list products product-cards">
                        <div
                            class="item"
                            v-for="(item, i) in products"
                            :key="i"
                        >
                            <ProductCard
                                :item="item"
                                :sliderIsClickble="true"
                                @goToProduct="goToProduct"
                                @addToCart="addToCart"
                            />
                        </div>
                    </div>
                </div>
                <button
                    class="market-button button"
                    @click="goToMarket"
                >{{ $t('Go to the market') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import ProductCard from './ProductCard.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'DiscountModal',
    components: {
        ProductCard,
    },
    props: {
        isAuth: {
            type: Boolean,
            required: true
        },
        currencySymbol: {
            type: String,
            required: true
        },
        currencyCode: {
            type: String,
            required: true
        }
    },
    computed: {
        ...mapGetters('discount', [
            'hasActiveDiscount',
            'getDiscountPercentage',
            'getDiscountDuration',
            'getProducts',
            'discountModalVisible',
        ]),
        discountDuration() {
            return this.getDiscountDuration;
        },
        discountPercentage() {
            return this.getDiscountPercentage;
        },
        products() {
            return this.getProducts;
        },
    },
    watch: {
    },
    methods: {
        ...mapActions('discount', ['closeDiscountModal']),
        addToCart(item) {
            this.$emit('addToCart', item.id, item.item_type);
        },
        goToProduct(item) {
            this.$emit('goToProduct', item);
            this.closeDiscountModal();
        },
        handleCloseModal() {
            this.closeDiscountModal();
        },
        goToMarket() {
            this.closeDiscountModal();
            this.$router.push('/products/all');
        },
    },
};
</script>

<style lang="scss" scoped>
.modal.discount-modal {
    font-family: 'Noto Sans', sans-serif;
    color: #fff;

    ::v-deep(.item-wrapper) {

        .preview {
            width: 124px;
            height: 80px;
            margin: 10px auto 20px;
            order: 2;
            padding-top: 0;

            img {
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
            }

        }

        .desc {
            margin-bottom: 10px;
        }

        .button {
            margin-top: 0;
        }
    }

    .product-list-section {
        overflow-y: auto;
        margin-top: 24px;
        margin-bottom: 24px;

        .products {
            margin-top: 0;
            width: 100%;
        }

        .products .item {
            width: 100%;
            padding-bottom: 0;
        }
    }

    .wrapper {
        max-width: 1047px;
        max-height: 95vh;
        // display: flex;
        // align-items: center;
        // justify-content: center;

    }

    .container {
        max-width: 1047px;
        max-height: 810px;
        padding: 32px;
        background: #3a3f56;
        box-shadow: 0px 8px 8px 0px #0000001F;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }
}

.modal-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 24px;
    line-height: 46px;
}

.modal-title {
    font-size: 32px;
    font-weight: 700;
}

.close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

.close {
    filter: invert(100%);
}

.discount-message {
    text-align: left;
    font-size: 16px;
    font-weight: 400;

}

.highlight {
    // background-color: #6D82F2;
    color: #2591B8;
    // padding: 2px 4px;
    // border-radius: 4px;
}

.modal .product-cards {
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    grid-template-columns: repeat(auto-fill, minmax(228px, 1fr));
    gap: 24px 18px;
    justify-content: center;
    padding-left: 0;
}

.product-cards>* {
    max-width: 228px;
    width: 100%;
}

.modal .market-button {
    margin-top: 0;
    width: 100%;
    padding: 13px;
    // border-radius: 16px;
    line-height: 26px;

}

@media (max-width: 768px) {
    .modal .button.market-button {
        width: 100%;
    }
}

@media (max-width: 600px) {
    .product-cards {
        grid-template-columns: 1fr;
    }
}
</style>