<template>
    <section class="section payment-methods-section">
        <div class="wrapper">
            <div class="title">
                {{ $t('Variety of supported payment methods') }}
            </div>
            <div class="list">
                <div v-for="method in paymentMethods" :key="method.title" class="item">
                    <img :src="require(`@/assets/img/${method.img}`)" class="img">
                    <div class="title small">
                        {{ method.title }}
                    </div>
                    <div class="desc">
                        {{ method.desc }}
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'PaymentMethodsSection',
    data() {
        return {
            paymentMethods: [
                {
                    img: 'mastercard.svg',
                    title: 'Mastercard',
                    desc: 'World leader in digital payments'
                },
                {
                    img: 'visa.svg',
                    title: 'Visa',
                    desc: 'Accepted in 210+ Countries'
                },
                {
                    img: 'help-circle.svg',
                    title: 'Coming soon',
                    desc: 'Fast and simple for your perfect experience'
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.payment-methods-section {
    .list {
        margin: 40px auto 0;
        display: flex;
        gap: 20px;
        max-width: 1170px;
        padding-bottom: 24px;
        flex-wrap: wrap;

        .item {
            text-align: center;
            flex: 1 0 calc(33.33% - 13.33px);
            border-radius: 4px;
            transition: .3s;
            padding: 24px;
            background-color: #516182;
            color: white;

            display: flex;
            flex-direction: column;
            align-items: center;

            &:hover {
                opacity: 0.6;
            }

            .img {
                width: auto;
                height: auto;
                max-width: 100%;
            }

            .title {
                font-size: 20px;
                font-weight: 700;
                line-height: 24px;
                margin-top: 16px;
                color: #2591B8;
            }

            .desc {
                margin-top: 8px;
                line-height: 20px;
                font-size: 16px;
                font-weight: 400;
                max-width: 169px;
                width: 100%;
            }
        }
    }
}

@media (max-width: 768px) {
    .payment-methods-section .list {
        flex-direction: column;
        gap: 10px;

        .item {
            flex: 1 0 100%;
        }
    }
}
</style>