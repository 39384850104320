<template>
    <div class="stat-block">
        <div class="stat-label">
            {{ label }}
        </div>
        <div class="stat-value">
            {{ value }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'StatBlock',
    props: {
        value: {
            type: [String, Number],
            required: true
        },
        label: {
            type: String,
            required: true
        },
    }
}
</script>

<style scoped>

.stat-block {
    flex: 1 1 386px;
    padding: 24px;
    border-radius: 4px;
    border: none;
    background-color: #516182;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    z-index: 1;
}

.stat-label {
    font-size: 36px;
    font-weight: 700;
    line-height: 50px;
    text-align: center;
    color: #2591B8;
    display: flex;
    align-items: center;
    justify-content: center;
}

.stat-value {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
}

@media (max-width: 768px) {
    .stat-block {}

    .stat-label {
        font-size: 32px;
        line-height: 40px;
    }

    .stat-value {
        font-size: 14px;
        line-height: 20px;
    }
}

@media (max-width: 600px) {
    .stat-block {}
}
</style>