<template>
  <main class="main page-inside">
    <div class="main-wrapper">
      <section class="section product-list-section">
        <div class="wrapper">

          <!-- <div class="title">
            {{ $t(`Store`) }}
          </div> -->

          <div class="filters-container">
            <div class="filters-text">Filters</div>

            <!-- Price Filter -->
            <div class="filter-dropdown price-filter" ref="priceDropdownRef"
              @click="toggleDropdown('priceListIsVisible')">
              <span>Price {{ barMinValue }} - {{ barMaxValue }}</span>

              <img src="@/assets/img/arrow-down.svg" :class="{ 'rotated': priceListIsVisible }" alt="arrow" />
              <div v-if="priceListIsVisible" class="dropdown-menu price-dropdown" @click="stopPropagation">
                <div class="price-input-container">
                  <label class="price-label">
                    <span class="min">Min:</span>
                    <input type="number" v-model.number="barMinValue" class="price-input" placeholder="0"
                      @input="updateMinValue" />
                  </label>
                  <input type="range" v-model.number="barMinValue" :min="0" :max="9999" @input="updateMinValue" />
                  <label class="price-label">
                    <span class="max">Max:</span>
                    <input type="number" v-model.number="barMaxValue" class="price-input" placeholder="9999"
                      @input="updateMaxValue" />
                  </label>
                  <input type="range" v-model.number="barMaxValue" :min="0" :max="9999" @input="updateMaxValue" />
                </div>
              </div>
            </div>

            <!-- Type Filter -->
            <div class="filter-dropdown type-filter" ref="typeDropdownRef" @click="toggleDropdown('typeListIsVisible')">
              <div>
                <!-- <div class="filter-label">Type</div> -->
                <div class="filter-value">
                  {{ category ? category : 'All types' }}
                </div>
              </div>
              <img src="@/assets/img/arrow-down.svg" :class="{ 'rotated': typeListIsVisible }" alt="arrow" />
              <div v-if="typeListIsVisible" class="dropdown-menu" @click="stopPropagation">
                <label v-for="(item) in categoryOptions" :key="item" class="checkbox-label">
                  <div class="checkbox-label-wrapper">
                    <input type="radio" :value="item" v-model="category" />
                    <div class="checkbox"></div>
                    <span class="desc">{{ item }}</span>
                  </div>
                </label>
              </div>
            </div>

            <!-- Quality Filter -->
            <!-- <div class="filter-dropdown quality-filter" ref="qualityDropdownRef"
              @click="toggleDropdown('qualityListIsVisible')">
              <div>
                <div class="filter-label">Quality</div>
                <div class="filter-value">{{ quality ? quality : 'All quality' }}</div>
              </div>
              <img src="@/assets/img/arrow-down.svg" :class="{ 'rotated': qualityListIsVisible }" alt="arrow" />
              <div v-if="qualityListIsVisible" class="dropdown-menu" @click="stopPropagation">
                <label v-for="(item) in qualityOptions" :key="item" class="checkbox-label">
                  <div class="checkbox-label-wrapper">
                    <input type="radio" :value="item" v-model="quality" />
                    <div class="checkbox"></div>
                    <span class="desc">{{ item }}</span>
                  </div>
                </label>
              </div>
            </div> -->

            <!-- Sort -->
            <div class="sort-container">
              <span class="sort-text">Sort:</span>
              <div class="filter-dropdown sort-dropdown" ref="sortDropdownRef"
                @click="toggleDropdown('sortListIsVisible')">
                <div>
                  <!-- <div class="filter-label">Sort by</div> -->
                  <div class="filter-value">{{ sort === 'desc' ? 'Highest price' : 'Lowest price' }}</div>
                </div>
                <img src="@/assets/img/arrow-down.svg" :class="{ 'rotated': sortListIsVisible }" alt="arrow" />
                <div v-if="sortListIsVisible" class="dropdown-menu" @click="stopPropagation">
                  <label class="checkbox-label">
                    <div class="checkbox-label-wrapper">
                      <input type="radio" name="desc" value="desc" v-model="sort" />
                      <div class="checkbox"></div>
                      <span class="desc">{{ $t("Highest price first") }}</span>
                    </div>
                  </label>
                  <label class="checkbox-label">
                    <div class="checkbox-label-wrapper">
                      <input type="radio" name="asc" value="asc" v-model="sort" />
                      <div class="checkbox"></div>
                      <span class="desc">{{ $t("Lowest price first") }}</span>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div :class="['products-container', { active: !filtersIsVisible }]">
            <Transition>
              <div class="list products" v-if="productList && productList.length">
                <div class="item" v-for="(item, i) in productList" :key="i">
                  <ProductCard :item="item" :currencySymbol="currencySymbol" @goToProduct="goToProduct" />
                </div>
              </div>

              <!-- <div v-else-if="isFetchingProducts" class="loading-products">
                <p>{{ $t("Loading products...") }}</p>
              </div> -->
            </Transition>

            <div v-if="(!productList || !productList.length) && !isFetchingProducts" class="no-products">
              <p>{{ $t("No products found") }}</p>
              <button class="button primary" @click="resetFilters">{{ $t("Reset filters") }}</button>
            </div>

          </div>

          <Pagination v-if="totalProducts" :total-pages="totalPages" v-model:per-page="perPage"
            v-model:current-page="activePage" @update:per-page="updatePerPage" @update:current-page="changePage" />
        </div>
      </section>
    </div>
  </main>
</template>
<script>
import { mapActions } from 'vuex';
import { debounce } from 'lodash';
import { onClickOutside } from '@vueuse/core';
import ProductCard from "../components/ProductCard.vue";
import Pagination from '../components/PaginationComponent.vue';
export default {
  name: "ProductListPage",

  props: {
    currencyCode: {
      type: String,
      required: true
    },
    currencySymbol: {
      type: String,
      required: true
    },
  },
  components: {
    Pagination,
    ProductCard,
  },
  data: function () {
    return {
      imgDomain: "",
      sort: "desc",
      category: "All types",
      quality: "All quality",
      categoryOptions: {},
      qualityOptions: {},
      barMinValue: 0,
      barMaxValue: 9999,
      productList: {},

      perPage: 20,
      activePage: 1,
      totalProducts: null,
      totalPages: 1,

      isFetchingProducts: true,

      filtersIsVisible: true,
      priceListIsVisible: false,
      typeListIsVisible: false,
      qualityListIsVisible: false,
      sortListIsVisible: false,

      priceDropdownRef: null,
      typeDropdownRef: null,
      qualityDropdownRef: null,
      sortDropdownRef: null,

      debounceFilterProducts: debounce(this.filterProducts, 300),

      specialCategory: "",
    };
  },
  watch: {
    currencyCode: function () {
      this.debounceFilterProducts();
    },
    barMinValue: function () {
      this.activePage = 1;
      this.debounceFilterProducts();
    },
    barMaxValue: function () {
      this.activePage = 1;
      this.debounceFilterProducts();
    },
    sort() {
      this.activePage = 1;
      this.debounceFilterProducts();
    },
    quality() {
      this.activePage = 1;
      this.debounceFilterProducts();
    },
    category() {
      this.activePage = 1;
      this.debounceFilterProducts();
    },
    $route() {
      this.activePage = 1;
      this.resetFilters();
      this.checkRouteParams();
    },
  },

  async mounted() {
    this.checkRouteParams();
    if (this.isAuth) {
      await this.fetchActiveDiscount(this.currencyCode);
      if (this.hasActiveDiscount) {
        this.openDiscountModal();
      }
    }

    onClickOutside(this.$refs.priceDropdownRef, () => this.priceListIsVisible = false);
    onClickOutside(this.$refs.typeDropdownRef, () => this.typeListIsVisible = false);
    onClickOutside(this.$refs.qualityDropdownRef, () => this.qualityListIsVisible = false);
    onClickOutside(this.$refs.sortDropdownRef, () => this.sortListIsVisible = false);
  },
  beforeUnmount() {
  },
  methods: {
    ...mapActions('discount', ['fetchActiveDiscount']),
    updateMinValue: debounce(function (event) {
      const newValue = typeof event === 'object' ? Number(event.target.value) : Number(event);
      this.barMinValue = Math.min(newValue, this.barMaxValue);
    }, 350),

    updateMaxValue: debounce(function (event) {
      const newValue = typeof event === 'object' ? Number(event.target.value) : Number(event);
      this.barMaxValue = Math.max(newValue, this.barMinValue);
    }, 350),
    stopPropagation(event) {
      event.stopPropagation();
    },
    toggleDropdown(dropdown) {
      this[dropdown] = !this[dropdown];
      ['priceListIsVisible', 'typeListIsVisible', 'qualityListIsVisible', 'sortListIsVisible'].forEach(key => {
        if (key !== dropdown) {
          this[key] = false;
        }
      });
    },
    checkRouteParams() {
      if (this.$route.params.type === "revolution-collection") {
        this.specialCategory = this.$route.params.type;
      } else if (this.$route.params.type && this.$route.params.type != 'all') {
        this.specialCategory = this.$route.params.type;
      } else {
        this.specialCategory = "";
      }
      this.activePage = 1;
      this.getCategoryOptions();
      this.getQualityOptions();
      this.filterProducts();
    },
    resetFilters() {
      this.quality = "All quality";
      this.category = "All types";
      this.barMinValue = 0;
      this.barMaxValue = 9999;
    },
    openFilter() {
      this.filtersIsVisible = !this.filtersIsVisible;
    },
    filterProducts() {
      this.isFetchingProducts = true;
      const params = new URLSearchParams({
        type: this.category !== "All types" ? this.category : "",
        quality: this.quality !== "All quality" ? this.quality : "",
        price_from: this.barMinValue,
        price_till: this.barMaxValue,
        sort: this.sort,
        page: this.activePage,
        limit: this.perPage,
        category: this.specialCategory,
        currency: this.currencyCode
      });

      this.$http.get(`${process.env.VUE_APP_API}items/list?${params}`)
        .then((res) => {
          this.productList = res.data.payload;
          this.totalProducts = res.data.meta.total;
          this.totalPages = res.data.meta.pages;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
        })
        .finally(() => {
          this.isFetchingProducts = false;
        });
    },
    changePage(page) {
      this.activePage = page;
      this.debounceFilterProducts();
    },
    updatePerPage(newPerPage) {
      this.perPage = newPerPage;
      this.activePage = 1;
      this.filterProducts();
    },
    getCategoryOptions() {
      this.$http
        .get(`${process.env.VUE_APP_API}items/filter-data?category=${this.specialCategory}`)
        .then((res) => {
          let newCategories = res.data.payload.types;
          if (this.specialCategory == 'pubg') {
            newCategories.unshift("All types");
          } else {
            newCategories.unshift("All types");
          }
          this.categoryOptions = newCategories;
        })
        .catch(() => {
        });
    },
    getQualityOptions() {
      this.$http
        .get(`${process.env.VUE_APP_API}items/filter-data?category=${this.specialCategory}`)
        .then((res) => {
          let newQuality = res.data.payload.qualities;
          newQuality.unshift("All quality");
          this.qualityOptions = newQuality;
        })
        .catch(() => {
        });
    },
    addToCart(item) {
      this.$emit('addToCart', item.item_id, item.item.item_type)
    },
    goToProduct(item) {
      this.$emit("goToProduct", item);
    },
  },
};
</script>
<style scoped lang="scss">
.product-list-section {
  margin-top: 20px;
}

.product-list-section .title {
  margin-bottom: 40px;
}

.filters-container {
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
}

.filters-text,
.sort-text {
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  text-align: left;
  margin-right: 16px;
}

.filter-dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
  border-radius: 8px;
  border: 1px solid #46566A;
  background: #516182;

  cursor: pointer;
  margin-right: 16px;
  max-width: 234px;
  width: 100%;
  height: 56px;
  position: relative;

  &.price-filter {
    color: #fff;
  }

  &.sort-dropdown {
    margin-right: 0;
  }

  .filter-label {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #fff;
  }

  .filter-value {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-transform: lowercase;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  img {
    width: 24px;
    height: 24px;
    transition: transform 0.3s ease;

    &.rotated {
      transform: rotate(180deg);
    }
  }

  .dropdown-menu {
    position: absolute;
    top: 60px;
    left: 0;
    background-color: #516182;
    padding: 16px;
    border: 1px solid #46566A;
    border-radius: 8px;
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    z-index: 10;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .price-dropdown {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .price-input-container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    width: 100%;
    margin-bottom: 8px;

    .price-label {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
    }

    .price-label .min,
    .price-label .max {
      width: 40px;
      display: inline-block;
    }

  }

  .price-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #46566A;
    border-radius: 8px;
    background-color: #3A3F56;
    color: white;
  }

  input[type="range"] {
    width: 100%;
    padding: 0;
    margin-top: 8px;
    background: inherit;
  }
}

.sort-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.no-products,
.loading-products {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
  height: 282px;
  color: white;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
}

@media (max-width: 1200px) {
  .filters-container {
    flex-wrap: wrap;
  }

  .filters-text,
  .sort-text {
    align-self: flex-start;
    margin-bottom: 16px;
  }

  .sort-container {
    flex-direction: column;
  }

  .filter-dropdown,
  .sort-container {
    margin-bottom: 16px;
    width: 100%;
    min-width: 100%;
  }

  // .products-container {
  //   max-height: 543px;
  //   overflow-y: auto;
  // }
}
</style>