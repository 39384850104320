<template>
  <div id="tidio-chat"></div>
</template>

<script>
export default {
  name: "TidioChat",
  mounted() {
    const injectScript = document.createElement("script");
    injectScript.src = "//code.tidio.co/fisbzmnfzwpelowfsoyzjgzrxs1ltqwc.js";
    injectScript.async = true;
    document.head.appendChild(injectScript);

    injectScript.onerror = () => {
      console.error("Error loading Tidio script");
    };
  },
};
</script>
