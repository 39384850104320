<template>
  <div class="product-card">
    <div v-if="hasDiscount && !lastSale" class="discount-badge">
      <img src="./../assets/img/discount-icon.svg" alt="Discount" class="discount-icon" />
    </div>
    <div v-if="sale && !hasDiscount" class="sale-badge">
      <img src="./../assets/img/flame.svg" alt="Sale" class="sale-icon" />
    </div>
    <div v-if="lastSale" class="last-sale-badge">
      <img src="./../assets/img/invoice.png" alt="Last Sale" class="last-sale-icon" />
    </div>

    <div class="image-container" @click="goToProduct(item)">
      <img :src="getImageUrl(item)" :alt="getTitle(item)" class="product-image" />
    </div>
    <div class="content-container" @click="goToProduct(item)">
      <h3 class="product-title">{{ getTitle(item) }}</h3>
      <div class="price-container">
        <div v-if="hasDiscount && !lastSale" class="price-group has-active-discount">
          <div class="old-price">{{ item.old_price }} {{ currencySymbol }}</div>
          <div class="new-price">
            <span class="discounted-price">{{ item.price }} {{ currencySymbol }}</span>
          </div>
        </div>
        <div v-else class="price-group">
          <div class="price">
            <span>{{ item.price }} {{ currencySymbol }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ProductCard',
  props: {
    item: {
      type: Object,
      required: true
    },
    sale: {
      type: Boolean,
      default: false
    },
    lastSale: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      imgDomain: ''
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN
  },
  computed: {
    ...mapGetters('app', ['currencySymbol']),
    hasDiscount() {
      return this.item && this.item.old_price && this.item.old_price !== '0.00';
    },
  },
  methods: {
    goToProduct(item) {
      this.$emit('goToProduct', item);
    },
    getTitle(item) {
      return item.title || item.name;
    },
    getImageUrl(item) {
      return item.item_type === 'pack'
        ? item.img_url || item.image_url
        : `${this.imgDomain}${item.img_url}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.product-card {
  padding: 24px;
  border-radius: 4px;
  // border: 1px solid #46566a;
  background: #516182;
  position: relative;

  .discount-badge,
  .sale-badge,
  .last-sale-badge {
    position: absolute;
    top: 12px;
    right: 12px;
    display: flex;
    align-items: center;
    border-radius: 12px;
    z-index: 1;
  }

  .discount-icon,
  .sale-icon,
  .last-sale-icon {
    width: 24px;
    height: 24px;
    pointer-events: none;
  }

  .image-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 180px;
    height: 80px;
    cursor: pointer;

    .product-image {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }

  .content-container {
    margin-top: 20px;
    cursor: pointer;

    .product-title {
      height: 48px;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      text-align: left;
      color: #ffffff;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .price-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 40px;

      .price-group {
        display: flex;
        align-items: center;
        gap: 12px;
        // padding: 4.5px 24px;
        // border-radius: 40px;
        max-width: 100%;
        // border: 1px solid #2591B8;
        height: 28px;
      }

      .price-group.has-active-discount {
        gap: 6px;
      }

      .old-price,
      .new-price {
        text-wrap: nowrap;
      }

      .old-price {
        font-size: 12px;
        font-weight: 700;
        line-height: 28px;
        text-align: left;
        color: #ffffff;
        text-decoration: line-through;
      }

      .new-price {
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
        text-align: left;
        color: #e14545;
      }

      .price {
        font-size: 20px;
        font-weight: 700;
        line-height: 27px;
        text-align: left;
        color: #2591B8;
      }
    }
  }
}
</style>